import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import * as Yup from 'yup';

export const setAuthToken = (token: string | boolean) => {
  if (typeof token == 'string') {
    Cookies.set('token', token);
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const setHeader = (token: string | boolean, tokenName: string) => {
  if (typeof token == 'string') {
    Cookies.set(tokenName, token);
    axios.defaults.headers.common[tokenName] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const UploadFileSchema = () => {
  const FILE_SIZE = 1000;
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  return Yup.mixed()
    .test('fileSize', 'File Size is too large', value => value.size <= FILE_SIZE)
    .test('fileType', 'Unsupported File Format', value => SUPPORTED_FORMATS.includes(value.type));
};
export const capitalize = (title: string) => (title ? title.charAt(0).toUpperCase() + title.slice(1) : '');

export const trimAndLower = (data: string) => data.toLowerCase().trim();

export const getPDFFromBuffer = (bufferArray: number[], filename?: string) => {
  try {
    const blob = new Blob([new Uint8Array(bufferArray).buffer], { type: 'application/pdf' });
    // const win = window.open('', '_blank');
    // const URL = window.URL || window.webkitURL;
    // const dataUrl = URL.createObjectURL(blob);
    // if (win) win.location = dataUrl;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('title', filename || 'Order Confirmation PDF');
      // link.setAttribute('download', filename || 'Order Confirmation PDF');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    console.log('Blob Error', error);
  }
};
export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
export const logout = () => {
  const jwtDecodedToken = Cookies.get('token') as undefined | string;
  const decoded: any = jwt_decode(jwtDecodedToken as string);
  console.log('LogOut', decoded);
  setAuthToken(false);
  setHeader(false, '');
  Cookies.remove('token');
  Cookies.remove('rememberme');
  // window.location.href = '/';
};

export const validateAuthentication = () => {
  const jwtDecodedToken = Cookies.get('token') as undefined | string;
  if (jwtDecodedToken) {
    // Non Null Assertion ! will remove undefined and null from a type without doing any explicit type checking
    try {
      const decoded: any = jwt_decode(jwtDecodedToken as string);
      console.log(decoded);
      const currentTime = Date.now() / 1000;
      if (decoded) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error', error);
      logout();
      return false;
    }
  } else {
    console.log('jwtDecodedToken');
    logout();
    return false;
  }
};

export const getDecodedToken = () => {
  const jwtDecodedToken = Cookies.get('token') as undefined | string;
  if (jwtDecodedToken) {
    // Non Null Assertion ! will remove undefined and null from a type without doing any explicit type checking
    const decoded: any = jwt_decode(jwtDecodedToken as string);

    return { decoded, isValid: true };
  } else {
    return { isValid: false };
  }
};

export const formatTime = (timeString: string) => {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'AM' : 'PM');
};

export const capitalizeFLetter = (data: string) => {
  return data.replace('-', ' ').replace(/^./, data[0].toUpperCase());
};

export const truncateStringTo100Words = (inputString: string) => {
  // Split the input string into an array of words
  const wordsArray = inputString.split(/\s+/);

  // Check if the number of words is less than or equal to 100
  if (wordsArray.length <= 10) {
    return inputString;
  }

  // Take the first 100 words
  const truncatedArray = wordsArray.slice(0, 10);

  // Join the words back into a single string
  const truncatedString = truncatedArray.join(' ');

  return truncatedString + '...'; // Add ellipsis to indicate truncation
};
export const randomUniqueName = () => {
  const names = ['cashless', 'top', 'best', 'indipendent', 'escort', 'cash payment escort'];
  const randomIndex = Math.floor(Math.random() * names.length);
  return names[randomIndex];
};
